import {
  getURLParam,
  LoadsExecutionFlow,
  LoadsMapView,
  LoadsTimelineView,
  loadWebsocketEvents,
  PageError,
  PageLoading,
  updateLoadAssetCurrentPosition,
  updateLoadExecution,
  updateLoadStatus,
  updateLoadTrips,
  useLoad,
  websocket,
} from '@trackmatic/yard-ui-common';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHeaderTextContext, useLogoContext } from 'src/common';
import { Container } from './styles';

const ViewLoad = ({ match }) => {
  const { setLogo } = useLogoContext();
  const { setHeaderText } = useHeaderTextContext();
  const [load, setLoad] = useState();
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [isRepressed, setIsRepressed] = useState(false);
  const { error } = useLoad({
    id: getURLParam(match, 'loadId'),
    onCompleted: (d) => {
      setLoad(d.getLoad);
    },
  });

  const secondaryColor = get(load, 'org.secondaryColor');
  const orgLogo = get(load, 'org.logo');

  useEffect(() => {
    setLogo(orgLogo);
    setHeaderText('Load Execution');
  }, [secondaryColor, orgLogo, setHeaderText, setLogo]);

  const loadId = get(load, 'id', '');

  useEffect(() => {
    if (loadId) {
      const loadUpdates = new WebSocket(websocket('loads', loadId));
      loadUpdates.onmessage = async (e) => {
        if (e.data) {
          const {
            payload,
            meta: { type: eventType },
          } = JSON.parse(e.data);
          switch (eventType) {
            case loadWebsocketEvents.STATUS_UPDATE:
              setLoad((prev) => updateLoadStatus({ load: prev, payload }));
              break;
            case loadWebsocketEvents.LOAD_EXECUTION:
              setLoad((prev) => updateLoadExecution({ load: prev, payload }));
              break;
            case loadWebsocketEvents.TRIP_SNAPSHOT:
              setLoad((prev) =>
                updateLoadAssetCurrentPosition({ load: prev, payload }),
              );
              break;
            case loadWebsocketEvents.TRIP_SUMMARY:
              setLoad((prev) => updateLoadTrips({ load: prev, payload }));
              break;
            default:
              break;
          }
        }
      };
      return () => {
        loadUpdates.close();
      };
    }
  }, [loadId]);

  if (error) {
    return <PageError error={error} />;
  }

  if (load) {
    if (load.status !== 'ACTIVE') {
      return (
        <PageError
          error={
            new Error('This load is no longer active and cannot be viewed.')
          }
        />
      );
    }
    return (
      <Container>
        <LoadsExecutionFlow
          load={load}
          canRepress
          isRepressed={isRepressed}
          onRepressClick={() => setIsRepressed((prev) => !prev)}
        />
        <LoadsMapView load={load} selectedTrips={selectedTrips} />
        <LoadsTimelineView
          load={load}
          setSelectedTrips={setSelectedTrips}
          selectedTrips={selectedTrips}
        />
      </Container>
    );
  }

  return <PageLoading />;
};

export default ViewLoad;

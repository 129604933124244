import { useQuery } from '@apollo/client';
import { getURLParam, PageLoading, theme } from '@trackmatic/yard-ui-common';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHeaderTextContext, useLogoContext } from 'src/common';
import Contacts from './Contacts';
import { GET_ENTITY } from './gql';
import { Container } from './styles';

const ViewLoad = ({ match }) => {
  const { setLogo } = useLogoContext();
  const { setHeaderText } = useHeaderTextContext();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const { data, error, refetch } = useQuery(GET_ENTITY, {
    variables: { id: getURLParam(match, 'linkEntityId'), token },
  });

  const orgLogo = data?.getLinkEntity.org.logo;

  useEffect(() => {
    setLogo(orgLogo);
    setHeaderText('Communication Management Console');
  }, [orgLogo, setHeaderText, setLogo]);

  if (error)
    return (
      <div style={{ color: theme.colors.danger }}>
        This link is invalid. Please contact Trackmatic for support.
      </div>
    );
  if (!data) return <PageLoading />;

  const { getLinkEntity: entity } = data;

  const name = entity.name;

  return (
    <Container>
      <p>
        This console is used to manage the contacts and preferences in the
        ongoing communication of delivery information for{' '}
        <strong>{entity.org.name}</strong>
      </p>
      <p>
        Below is the list of Contacts currently listed for{' '}
        <strong>{name}</strong>. Feel free to edit or delete existing contacts
        or create a new contact as required
      </p>
      <Contacts
        refetch={refetch}
        contacts={entity.loadContacts}
        entityId={entity.id}
        currentEntity={{ id: getURLParam(match, 'orgId'), type: 'org', name }}
        token={token}
      />
    </Container>
  );
};

export default ViewLoad;

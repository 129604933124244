import {
  InlineNotification,
  NotificationsContext,
} from '@trackmatic/yard-ui-common';
import React, { useContext } from 'react';
import { BodyContainer } from './styles';

const Body = ({ children }) => {
  const { inlineAlert } = useContext(NotificationsContext);
  return (
    <BodyContainer>
      {inlineAlert && (
        <InlineNotification
          kind={inlineAlert.type}
          title={inlineAlert.title}
          subtitle={inlineAlert.message}
        />
      )}
      {children}
    </BodyContainer>
  );
};

export default Body;

import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 4fr 1fr;
  grid-gap: 0.5rem;
  grid-template-areas:
    'execution map'
    'execution timeline';
  width: 100%;
  height: 91vh;
  font-size: 0.75rem;
`;

export { Container };

import React, { createContext, useContext, useState } from 'react';

const LogoContext = createContext();

const LogoProvider = ({ children }) => {
  const [logo, setLogo] = useState();
  return (
    <LogoContext.Provider value={{ logo, setLogo }}>
      {children}
    </LogoContext.Provider>
  );
};

const useLogoContext = () => {
  const { logo, setLogo } = useContext(LogoContext);
  return { logo, setLogo };
};

export { LogoContext, LogoProvider, useLogoContext };

import { useMutation } from '@apollo/client';
import { Edit16 } from '@carbon/icons-react';
import {
  DeleteIconSmall,
  formatDateTime,
  generatePrimaryIcon,
  LoadsCreateContactCommonModule,
  LoadsEditContactCommonModule,
  ModalContext,
  OverflowContainer,
  PrimaryButton,
  Table,
  Td,
  Th,
  TrueIconSmall,
  useAlerts,
  useLoading,
} from '@trackmatic/yard-ui-common';
import React, { useContext } from 'react';
import { FaEnvelope, FaMobileAlt } from 'react-icons/fa';
import { MANAGE_CONTACT_ENTITIES } from './gql';
import { ContactContainer } from './styles';

const Contacts = ({
  contacts = [],
  refetch,
  entityId,
  currentEntity,
  token,
}) => {
  const { onError } = useAlerts();
  const [manageEntities, { loading }] = useMutation(MANAGE_CONTACT_ENTITIES, {
    onError,
  });
  const { toggleModal, setModalContent, clearModal, setDimensions } =
    useContext(ModalContext);
  const Edit = generatePrimaryIcon(Edit16);

  useLoading(loading);
  return (
    <ContactContainer>
      {currentEntity.type === 'org' && (
        <PrimaryButton
          style={{ justifySelf: 'end', width: 'auto', padding: '0.5rem' }}
          onClick={() => {
            toggleModal();
            setModalContent(
              <div style={{ width: '100%' }}>
                <LoadsCreateContactCommonModule
                  isRoutes
                  currentEntity={currentEntity}
                  onSubmit={async ({ createLoadContact }) => {
                    clearModal();
                    await manageEntities({
                      variables: {
                        input: {
                          id: createLoadContact.id,
                          add: [entityId],
                          remove: [],
                        },
                        token,
                      },
                    });
                    refetch();
                  }}
                  token={token}
                />
              </div>,
            );
            setDimensions({ height: 95, width: 95 });
          }}
        >
          Add New Contact
        </PrimaryButton>
      )}
      <OverflowContainer>
        <Table>
          <thead>
            <tr>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Mobile</Th>
              <Th>Role</Th>
              <Th>Last Event</Th>
              <Th center>
                <FaEnvelope />
              </Th>
              <Th center>
                <FaMobileAlt />
              </Th>
              <Th />
              <Th />
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => {
              const [hasEmail, hasSms] = ['email', 'sms'].map((key) =>
                contact.comsEvents?.some((se) => se[key]),
              );
              return (
                <tr key={contact.id}>
                  <Td>{contact.firstName}</Td>
                  <Td>{contact.lastName}</Td>
                  <Td>{contact.email}</Td>
                  <Td>{contact.mobile}</Td>
                  <Td>{contact.position}</Td>
                  <Td>{formatDateTime(contact.lastEvent)}</Td>
                  <Td center>{hasEmail && <TrueIconSmall />}</Td>
                  <Td center>{hasSms && <TrueIconSmall />}</Td>
                  <Td style={{ width: '30px' }} center>
                    <Edit
                      onClick={() => {
                        toggleModal();
                        setModalContent(
                          <div style={{ width: '100%' }}>
                            <LoadsEditContactCommonModule
                              isRoutes
                              currentEntity={currentEntity}
                              contactId={contact.id}
                              onSubmit={() => {
                                clearModal();
                                refetch();
                              }}
                              token={token}
                            />
                          </div>,
                        );
                        setDimensions({ height: 95, width: 95 });
                      }}
                    />
                  </Td>
                  <Td center style={{ width: '30px' }}>
                    <DeleteIconSmall
                      onClick={async () => {
                        await manageEntities({
                          variables: {
                            input: {
                              id: contact.id,
                              add: [],
                              remove: [entityId],
                            },
                            token,
                          },
                        });
                        refetch();
                      }}
                    />
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </OverflowContainer>
    </ContactContainer>
  );
};

export default Contacts;

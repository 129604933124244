import { DividerLine, trackmaticLogo } from '@trackmatic/yard-ui-common';
import React from 'react';
import { useHeaderTextContext, useLogoContext } from 'src/common';
import { HeaderContainer, Logo } from './styles';

const Header = () => {
  const { logo } = useLogoContext();
  const { headerText } = useHeaderTextContext();
  return (
    <HeaderContainer>
      <Logo src={logo || trackmaticLogo} />
      <DividerLine />
      <div>{headerText}</div>
    </HeaderContainer>
  );
};

export default Header;

import { useDashCam } from '@trackmatic/yard-ui-common';
import React from 'react';
import ReactPlayer from 'react-player';
import { Container } from './styles';

const DashCam = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const [surfsightId, deviceId] = ['surfsightId', 'deviceId'].map((v) =>
    searchParams.get(v),
  );

  const { livestreamUrls } = useDashCam(
    { devices: [{ deviceId, type: 'DASH_CAM' }] },
    surfsightId,
  );

  if (surfsightId && deviceId) {
    return (
      <Container>
        {livestreamUrls.map((url) => (
          <ReactPlayer url={url} key={url} playing controls width="90vw" />
        ))}
      </Container>
    );
  }
  return (
    <div>
      Please supply <code>surfsightId</code> and <code>deviceId</code> search
      parameters
    </div>
  );
};

export default DashCam;

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
`;
const Box = styled.div`
  display: flex;
  height: 50%;
  width: 50%;
  border: 1px solid black;
  margin-bottom: 2rem;
`;
const P = styled.div`
  padding: 1rem;
`;

export { Container, Box, P };

import { gql } from '@apollo/client';

const GET_ENTITY = gql`
  query getLinkEntity($id: ID!, $token: String) {
    getLinkEntity(id: $id, token: $token) {
      id
      name
      org {
        id
        name
        logo
        primaryColor
        secondaryColor
      }
      loadContacts {
        id
        firstName
        lastName
        email
        mobile
        position
        lastEvent
        comsEvents {
          email
          sms
        }
      }
    }
  }
`;

const MANAGE_CONTACT_ENTITIES = gql`
  mutation manageLoadContactEntities($input: IdAddRemove!, $token: String) {
    manageLoadContactEntities(input: $input, token: $token) {
      id
    }
  }
`;

export { GET_ENTITY, MANAGE_CONTACT_ENTITIES };

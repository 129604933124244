import React, { createContext, useContext, useState } from 'react';

const HeaderTextContext = createContext();

const HeaderTextProvider = ({ children }) => {
  const [headerText, setHeaderText] = useState('');
  return (
    <HeaderTextContext.Provider value={{ headerText, setHeaderText }}>
      {children}
    </HeaderTextContext.Provider>
  );
};

const useHeaderTextContext = () => {
  const { headerText, setHeaderText } = useContext(HeaderTextContext);
  return { headerText, setHeaderText };
};

export { HeaderTextContext, HeaderTextProvider, useHeaderTextContext };

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routing } from 'src/common';
import { DashCam, Entity, LoadExecution, SurfsightDownload, Unsubscribe } from './modules';

const Routes = () => {
  return (
    <Switch>
      <Route path={routing.entityView} component={Entity} />
      <Route path={routing.unsubscribeView} component={Unsubscribe} />
      <Route path={routing.dashCamView} component={DashCam} />
      <Route path={routing.loadExecutionView} component={LoadExecution} />
      <Route path={routing.surfsightDownload} component={SurfsightDownload} />
    </Switch>
  );
};

export default Routes;

import styled from 'styled-components';

const HeaderContainer = styled.div`
  width: 100%;
  height: ${({ theme, isDashboardMode }) =>
    theme[isDashboardMode ? 'dashboardHeaderHeight' : 'headerHeight']};
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  padding: 0.2rem;
  align-items: center;
  grid-template-columns: auto auto auto auto 1fr;
  font-size: 0.7rem;
`;
const Logo = styled.img`
  height: ${({ theme, isDashboardMode }) =>
    `${
      parseInt(
        theme[
          isDashboardMode ? 'dashboardHeaderHeight' : 'headerHeight'
        ].replace('px', ''),
      ) - 15
    }px`};
`;

export { HeaderContainer, Logo };

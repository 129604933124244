import { useMutation } from "@apollo/client";
import {
  ConfirmModal,
  ModalContext,
  PrimaryButton,
  useAlerts,
} from "@trackmatic/yard-ui-common";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHeaderTextContext } from "src/common";
import { MANAGE_UNSUBSCRIBE } from "./gql";
import { Box, Container, P } from "./styles";

const Unsubscribe = () => {
  const { setHeaderText } = useHeaderTextContext();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const emailRegex = /unsubscribe\/([^\/]+)$/;
  const emailMatch = emailRegex.exec(pathname);
  const email = emailMatch ? emailMatch[1] : null;

  const { onError, alertSuccess } = useAlerts();

  const { toggleModal, setModalContent, clearModal, setDimensions } =
    useContext(ModalContext);

  const [manageUnsubscribe, { loading }] = useMutation(MANAGE_UNSUBSCRIBE, {
    onCompleted: () => {
      alertSuccess("Unsubscribed Successfully");
      clearModal();
    },
    onError,
  });

  useEffect(() => {
    setHeaderText("Unsubscribe Management Console");
  }, [setHeaderText]);

  return (
    <Container>
      <Box>
        <P>
          Unsubscribe <strong>{email}</strong> We sorry to see you go...
        </P>
      </Box>

      <PrimaryButton
        style={{ justifySelf: "end", width: "15%", padding: "0.5rem" }}
        onClick={() => {
          toggleModal();
          setModalContent(
            <div style={{ width: "100%" }}>
              <ConfirmModal
                title={`Unsubscribe ${email}`}
                subtitle="Are you sure?"
                danger
                onCancel={clearModal}
                onConfirm={() =>
                  manageUnsubscribe({
                    variables: { email: email, token },
                  })
                }
                loading={loading}
              />
            </div>
          );
          setDimensions({ height: 50, width: 50 });
        }}
      >
        Unsubscribe
      </PrimaryButton>
    </Container>
  );
};

export default Unsubscribe;

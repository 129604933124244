import styled from 'styled-components';

const Container = styled.div`
  & p {
    margin-bottom: 0.5rem;
  }
`;

const ContactContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
  height: 50vh;
  overflow: auto;
`;

export { Container, ContactContainer };

import { ApolloProvider as HooksProvider } from '@apollo/client';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useClient from './apolloClient';

const ApolloProvider = ({ children }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const client = useClient(params.get('token'));
  return <HooksProvider client={client}>{children}</HooksProvider>;
};

export default ApolloProvider;

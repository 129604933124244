import {
  GoogleMapsLoaderProvider,
  initiateSentry,
  LoadingProvider,
  MapCenterProvider,
  ModalProvider,
  NotificationsProvider,
  SentryErrorBoundary,
  setDocumentTitle,
  ThemeProvider,
} from '@trackmatic/yard-ui-common';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider, HeaderTextProvider, LogoProvider } from 'src/common';
import { Body, Header } from './containers';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';

initiateSentry();
setDocumentTitle('');

ReactDOM.render(
  <React.StrictMode>
    <SentryErrorBoundary>
      <Router>
        <ApolloProvider>
          <ThemeProvider>
            <LoadingProvider>
              <NotificationsProvider>
                <GoogleMapsLoaderProvider>
                  <ModalProvider>
                    <MapCenterProvider>
                      <LogoProvider>
                        <HeaderTextProvider>
                          <Header />
                          <Body>
                            <Routes />
                          </Body>
                        </HeaderTextProvider>
                      </LogoProvider>
                    </MapCenterProvider>
                  </ModalProvider>
                </GoogleMapsLoaderProvider>
              </NotificationsProvider>
            </LoadingProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Router>
    </SentryErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console..log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { PageError, PrimaryButton, Spinner } from '@trackmatic/yard-ui-common';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

const SurfsightDownload = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const [deviceId, fileType, cameraId, file] = [
    'deviceId',
    'fileType',
    'cameraId',
    'file',
  ].map((v) => searchParams.get(v));

  const hasAllParams = deviceId && fileType && cameraId && file;

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const download = useCallback(async () => {
    if (!hasAllParams) return;
    try {
      setLoading(true);
      const tpsUrl = `https://tps.trackmatic.cloud/rest/v1/surfsight/camerafile/${deviceId}?fileType=${fileType}&cameraId=${cameraId}&file=${file}`;
      const {
        data: { url },
      } = await axios.get(tpsUrl);
      const a = document.createElement('a');
      a.href = url;
      a.click();
      a.remove();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [deviceId, fileType, cameraId, file, hasAllParams, setError]);

  useEffect(download, [download]);

  if (error) return <PageError error={error} />;

  if (!hasAllParams)
    return (
      <div>
        Please ensure all parameters are present: deviceId, fileType, cameraId,
        file
      </div>
    );

  return (
    <div>
      {loading && <Spinner small />}
      <PrimaryButton onClick={download}>
        Downloading file for you. If file does not automatically download,
        please click here
      </PrimaryButton>
    </div>
  );
};

export default SurfsightDownload;

import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

const useClient = (token) => {
  const httpLink = new HttpLink({
    uri:
      process.env.REACT_APP_SERVER_URL &&
      process.env.REACT_APP_SERVER_URL.replace(/'/g, ''),
    // uri: 'http://0.0.0.0:80/graphql', //UNCOMMENT THIS WHEN TESTING LOCALLY
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const authHeader = { authorization: token };
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...authHeader,
      },
    }));
    return forward(operation);
  });

  const cache = new InMemoryCache({
    addTypename: false,
  });

  const client = new ApolloClient({
    link: from([authMiddleware, httpLink]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  });
  return client;
};

export default useClient;

import styled from 'styled-components';

const determineHeaderHeight = ({ theme, isDashboardMode }) => {
  const headerHeight =
    theme[isDashboardMode ? 'dashboardHeaderHeight' : 'headerHeight'];
  return `calc(100vh - ${headerHeight})`;
};

const BodyContainer = styled.div`
  height: ${determineHeaderHeight};
  color: ${({ theme }) => theme.colors.darkGrey};
  width: 100vw;
  padding: 0.5rem;
`;

export { BodyContainer };
